import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {getCall, restApiPrefix, urls} from "../../utils/ApiUtils";
import '../HomePage/homePage.css'


/**
 *
 * @param
 * @constructor
 */
const AdminPage = ({setPageTitle}) => {
    const classes = useStyles();
    const [solutions, setSolutions] = useState(null);
    const [loading, setLoading] = useState(true);
    //state variables

    //useEffects(Lifecycle Methods)
    /**
     * ComponentDidMount: What should happen when this component is first loaded into the DOM
     */
    useEffect(() => {
      setPageTitle("Admin Page");
        const fetchAdminSolutions = async () => {
            if (!solutions) {
                try {
                    const result = await getCall(urls.adminSolutions);
                    console.log(result);
                    setSolutions(result)
                } catch (error) {
                    console.error("Error fetching solutions:", error);
                }
            }
            setLoading(false); // Set loading to false regardless of whether data was fetched
        };
        fetchAdminSolutions();
    }, [setPageTitle, solutions]);

    //Other Methods
    if (loading) {
        return <div>Loading...</div>; // Show a loading state while data is being fetched
    }

    return (
      <div id='base' className="base">
          <Link to="/users" className={`biggerLink solution-item`} style={{ textDecoration: 'none' }}><div>Users</div></Link>
        <Link to="/logins" className={`biggerLink solution-item`} style={{ textDecoration: 'none' }}><div>Login Log</div></Link>
          <Link to="/constants" className={`biggerLink solution-item`} style={{ textDecoration: 'none' }}><div>Constants</div></Link>
          {solutions.map((solution) => (
              <Link to={`${restApiPrefix + solution.url}`} className={`biggerLink solution-item`} style={{ textDecoration: 'none' }}><div>{solution.title}</div></Link>
          ))}
      </div>
    );
};

export default AdminPage;

const useStyles = makeStyles((theme) => ({
    base: {
        margin: '33px'
    },
    biggerLink: {
      fontSize: '20px',
      fontWeight: 'bolder',
      color: '#053b71'
    }

}));
