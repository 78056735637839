import React from 'react';

const ExternalLinkIcon = ({ size = 24, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={size}
            height={size}
            fill={color}
            aria-label="External Link Icon"
        >
            <path d="M14 3h6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V5.414l-9.293 9.293a1 1 0 0 1-1.414-1.414L17.586 4H14a1 1 0 1 1 0-2z" />
            <path d="M4 8a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H6v9h9v-4a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8z" />
        </svg>
    );
};

export default ExternalLinkIcon;
