function Header({ modelTitle, pageTitle }) {
  return (
    <div className="header">
      <h2>{modelTitle}</h2>
      <h1>{pageTitle}</h1>
      {/* other header content */}
    </div>
  );
}

export default Header;
