import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import {getCall, urls, restApiPrefix} from "../../utils/ApiUtils";
import './homePage.css';
import ExternalLinkIcon from "../../resources/images/externalLinkIcon";

// import {isAuthenticated} from "../../utils/Authentication";



/**
 *
 * @param
 * @constructor
 */
const HomePage = ({setPageTitle, solutions}) => {
  const classes = useStyles();
  //const [solutions, setSolutions] = useState(null);
  //const [loading, setLoading] = useState(true);
  //state variables

  //useEffects(Lifecycle Methods)
  /**
   * ComponentDidMount: What should happen when this component is first loaded into the DOM
   */
  useEffect(() => {
    setPageTitle("Home Page");
    //setSolutions(solutions);
    // const fetchSolutions = async () => {
    //   if (!solutions) {
    //     try {
    //       const result = await getCall(urls.solutions);
    //       console.log(result);
    //       setSolutions(result)
    //     } catch (error) {
    //       console.error("Error fetching solutions:", error);
    //     }
    //   }
    //   setLoading(false); // Set loading to false regardless of whether data was fetched
    // };
    // fetchSolutions();
  }, [setPageTitle, solutions]);

  //Other Methods
  // if (loading) {
  //   return <div>Loading...</div>; // Show a loading state while data is being fetched
  // }

  return (
      <div>
        <div id="base" className="base">
          <div className="content-header">
            <h1 className="page-title">Available Solutions</h1>
          </div>
          {solutions?.map((solution) => (
              <Link
                  to={`${restApiPrefix + solution.url}`}
                  className="solution-item"
                  key={solution.url}
                  //target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Security best practice
              >
                  <div className="solution-card">
                      {solution.icon && (
                          <div className="solution-icon">
                              <img src={solution.icon} alt="Solution Icon" className="icon-image" />
                          </div>
                      )}
                      <div className="solution-content">
                          <div className="solution-title">{solution.title}</div>
                          <div className="solution-description">{solution.description}</div>
                      </div>
                      <div className="external-link-icon">
                          <ExternalLinkIcon size={16} color="#007BFF" />
                      </div>
                  </div>
              </Link>
          ))}
        </div>
      </div>
  );
};

export default HomePage;

const useStyles = makeStyles((theme) => ({
  base: {
    margin: '33px',
  },
  biggerLink: {
    fontSize: '20px',
    fontWeight: 'bolder',
    color: '#053b71'
  }

}));
