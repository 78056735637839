import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import PasswordModal from "../AccountPage/PasswordModal";
import './Account.css';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';



/**
 *
 * @param
 * @constructor
 */
const AccountPage = ({setPageTitle, user}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  //state variables

  //useEffects(Lifecycle Methods)
  /**
   * ComponentDidMount: What should happen when this component is first loaded into the DOM
   */
  useEffect(() => {
      setPageTitle("User Account");
      setLoading(false);
  }, [setPageTitle]);

  //Other Methods
  if (loading) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

    const handleEdit = (user) => {
        if(user) {
            setModalOpen(true);
        }
    }

    const afterSave = async () => {
        setModalOpen(false);
    }
    const handleClose = () => {
        setModalOpen(false);
    }
  return (
      <div id="base" className={classes.base}>
          {/* Welcome message */}
          <Typography
              variant="h4"
              align="center"
              gutterBottom
              style={{ marginTop: "20px", marginBottom: "30px" }}
          >
              Welcome, {user?.firstName} {user?.lastName}
          </Typography>

          {/* Card with user information */}
          <Card
              style={{
                  maxWidth: "500px",
                  margin: "0 auto",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              }}
          >
              <CardContent>
                  <Box mb={2}>
                      <Typography variant="body1" align="left">
                          <strong>First Name:</strong> {user?.firstName}
                      </Typography>
                  </Box>
                  <Box mb={2}>
                      <Typography variant="body1" align="left">
                          <strong>Last Name:</strong> {user?.lastName}
                      </Typography>
                  </Box>
                  <Box mb={2}>
                      <Typography variant="body1" align="left">
                          <strong>Email:</strong> {user?.username}
                      </Typography>
                  </Box>
                  <Box mt={4} style={{ textAlign: "left" }}>
                      <Button
                          variant="contained"
                          color="primary"
                          style={{
                              backgroundColor: "#0d4a8a",
                          }}
                          onClick={() => handleEdit(user)}
                      >
                          Change Password
                      </Button>
                  </Box>
              </CardContent>
          </Card>

          {/* Modal */}
          {modalOpen && !loading && (
              <PasswordModal
                  open={modalOpen}
                  email={user?.username}
                  handleClose={handleClose}
                  afterSave={afterSave}
              />
          )}
      </div>

);
};

export default AccountPage;

const useStyles = makeStyles((theme) => ({
  base: {
    margin: '33px',
    marginTop: '60px'
  },
  biggerLink: {
    fontSize: '20px'
  }

}));
