import React, {useEffect, useState} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
// import {Route, Routes} from "react-router";
import HomePage from "./containers/HomePage/HomePage";
import AdminPage from "./containers/AdminPage/AdminPage";
import UsersPage from "./containers/UsersPage/UsersPage";
import LoginsPage from "./containers/LoginsPage/LoginsPage";
import ConstantsPage from "./containers/ConstantsPage/ConstantsPage";
import {RequireAuth, isAuthenticated, userIsAdmin} from "./utils/Authentication";
import SignIn from "./containers/Login/SignIn";
import SignUp from "./containers/Login/SignUp";
import Header from "./components/Header";
import UserMenu from "./components/UserMenu";
import AboutModal from "./components/AboutModal";
import Logout from "./containers/Logout/Logout";
import AccountPage from "./containers/AccountPage/AccountPage";
import {getUserProfile} from "./utils/Authentication";
import {constantMap, getConstants, fetchServiceVersion} from "./utils/ApiUtils";
import PasswordModal from "./containers/AccountPage/PasswordModal";
function App() {
  const uiVersion = 0.02;
  const [serviceVersion, setServiceVersion] = useState(false);
  const [loggedIn, setLoggedIn] = useState(isAuthenticated());
  const [pageTitle, setPageTitle] = useState('');
  const [model, setModel] = useState('')
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [solutions, setSolutions] = useState(null);
  const setUserProfile = () => {
    getUserProfile().then(function(result) {
      setUser(result);
    });
  };

  useEffect(() => {
    if(!constantMap.GATEWAY_TITLE){
      getConstants(0,0).then(function(){
        console.log("constant page tile: " + constantMap.GATEWAY_TITLE);
        setModel(constantMap.GATEWAY_TITLE || "Gateway");
        setSolutions(JSON.parse(constantMap.Solutions));
        setLoading(false);
      });
    } else {
      setModel(constantMap.GATEWAY_TITLE);
    }
    getUserProfile().then(function(result) {
      setUser(result);
    });
    fetchServiceVersion().then(function(result) {
      setServiceVersion(result);
      if(solutions) setLoading(false);
    });

  }, []);
  const handleAboutModalOpen = () => {
    setAboutModalOpen(true);
  };
  const handleAboutModalClose = () => {
    setAboutModalOpen(false);
  }
  return (
    <Router basename='/home'>
      <div id="router-div">
        <nav id="router-nav" style={{display: window.self !== window.top ? "none" : undefined}}>
          <div id="header">
            <ul className="crumbar-ul row1">
              {pageTitle !== "Login" ? (
                  <li>
                    <Link to="/" className={loggedIn === true ? '' : 'hidden'} class={pageTitle === "Home Page" ? "onPage" : ""} style={{ textDecoration: 'none', paddingLeft: '33px'}}>Home</Link>
                  </li>) : (null)}
              {pageTitle === "User Account" ? (
                  <li class="breadcrumb">-
                    <Link to="/account" class="onPage" style={{ textDecoration: 'none'}}>Account</Link>
                  </li>) : (null)}
              {pageTitle === "About" ? (
                  <li class="breadcrumb">-
                    <Link to="/about" class="onPage" style={{ textDecoration: 'none'}}>About</Link>
                  </li>) : (null)}
              {pageTitle === "Admin Page" || pageTitle === "Users"|| pageTitle === "Setup User" || pageTitle === "Login Log" || pageTitle === "Constants" ? (
                  <li class="breadcrumb">-
                    <Link to="/admin" class={pageTitle === "Admin Page" ? "onPage" : ""} style={{ textDecoration: 'none'}}>Admin</Link>
                  </li>) : (null)}
              {pageTitle === "Users" || pageTitle === "Setup User" ? (
                  <li class="breadcrumb">-
                    <Link to="/users" class={pageTitle === "Users" ? "onPage" : ""} style={{ textDecoration: 'none'}}>Users</Link>
                  </li>) : (null)}
              {pageTitle === "Setup User" ? (
                  <li class="breadcrumb">-
                    <Link to="/register" class="onPage" style={{ textDecoration: 'none'}}>Setup User</Link>
                  </li>) : (null)}
              {pageTitle === "Login Log" ? (
                <li class="breadcrumb">-
                  <Link to="/logins" class="onPage" style={{ textDecoration: 'none'}}>Login Log</Link>
                </li>) : (null)}
              {pageTitle === "Constants" ? (
                  <li class="breadcrumb">-
                    <Link to="/constants" class="onPage" style={{ textDecoration: 'none'}}>Constants</Link>
                  </li>) : (null)}

            </ul>
                <div id="logo" class="row1">
                  {pageTitle !== "Login" ? (
                  <Header modelTitle={model} pageTitle={pageTitle}/>) : (null)}
                </div>
            <ul class="navbar-ul row1">
              {loggedIn ? <UserMenu
                  userIsAdmin={userIsAdmin}
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  onAboutModalOpen={handleAboutModalOpen}
              >
              </UserMenu> : ''}
            </ul>
          </div>

          {aboutModalOpen && !loading &&
              <AboutModal
                  open={aboutModalOpen}
                  uiVersion={uiVersion}
                  serviceVersion={serviceVersion.version}
                  databaseVersion={serviceVersion.dbVersion}
                  onClose={handleAboutModalClose} />}
        </nav>

        <div className="page-container">
          <Routes>
            <Route path="/" element={<RequireAuth role="user"><HomePage  setPageTitle={setPageTitle} solutions={solutions} /></RequireAuth>}/>
            <Route path="/login" element={<SignIn setLoggedIn={setLoggedIn} setUserProfile={setUserProfile} setPageTitle={setPageTitle} />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<RequireAuth role="admin"><SignUp  setPageTitle={setPageTitle} /></RequireAuth>}/>
            <Route path="/admin" element={<RequireAuth role="admin"><AdminPage  setPageTitle={setPageTitle} /></RequireAuth>}/>
            <Route path="/users" element={<RequireAuth role="admin"><UsersPage  setPageTitle={setPageTitle} /></RequireAuth>}/>
            <Route path="/logins" element={<RequireAuth role="admin"><LoginsPage  setPageTitle={setPageTitle} /></RequireAuth>}/>
            <Route path="/constants" element={<RequireAuth role="admin"><ConstantsPage  setPageTitle={setPageTitle} /></RequireAuth>}/>
            <Route path="/account" element={<RequireAuth role="user"><AccountPage  setPageTitle={setPageTitle} user={user}/></RequireAuth>}/>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
